<template>
  <v-container
    fluid
    py-0
    class="blue"
    style="position: fixed; overflow: scroll; height: 100vh"
  >
    <v-row align="center" justify="center" style="height: 100vh">
      <v-card class="pa-8 ma-5" width="380">
        <v-card-title class="d-flex justify-center"> sweeep </v-card-title>
        <div v-if="!processing">
          <div v-if="error">
            <label for="">{{ $t('message.invalid_invitation') }}</label>
          </div>

          <div v-if="!error">
            <div v-if="activated">
              <label v-if="invitationValid">招待が有効になりました</label>
              <label v-else for="">
                {{ $t('message.invite_already_activated') }}
              </label>
            </div>

            <div v-if="!activated">
              <div v-if="expired">
                <label>
                  {{ $t('message.invite_has_expired') }}
                </label>
              </div>

              <div v-if="!expired">
                <span>
                  {{ invalid_password_message }}
                </span>

                <v-form ref="form" v-model="valid">
                  <div class="mb-5">
                    {{ invite_user_name }} さんがあなたを
                    {{ organization_name }} に招待しています。
                  </div>

                  <template v-if="new_account">
                    <template v-if="roleId !== 4">
                      <v-text-field
                        v-model="full_name"
                        :label="fullname_label"
                        :hint="fullname_hint"
                        :rules="nameRules"
                        required
                        prepend-inner-icon="feather-user"
                        class="mb-5"
                      />
                      <v-text-field
                        v-model="password"
                        :label="password_label"
                        :rules="passwordRules"
                        type="password"
                        validate-on-blur
                        :hint="$t('message.password_validation')"
                        required
                        prepend-inner-icon="feather-lock"
                        class="mb-5"
                      />

                      <v-text-field
                        v-model="confirm_password"
                        :label="confirmpassword_label"
                        type="password"
                        :rules="passwordRules_2"
                        validate-on-blur
                        :hint="$t('message.password_validation')"
                        required
                        prepend-inner-icon="feather-lock"
                        class="mb-5"
                      />
                    </template>
                  </template>

                  <template v-else-if="roleId !== 4">
                    <span> <br />パスワードを入力してください. </span>
                    <v-text-field
                      v-model="password"
                      :label="password_label"
                      :rules="passwordRules"
                      type="password"
                      validate-on-blur
                      required
                      prepend-inner-icon="feather-lock"
                      class="mb-5"
                    />
                  </template>

                  <v-btn
                    large
                    :loading="loading"
                    block
                    color="blue"
                    dark
                    @click="submit"
                  >
                    <span v-if="new_account">{{
                      $t('message.signup_confirm_new')
                    }}</span>
                    <span v-else>{{
                      $t('message.signup_confirm_already')
                    }}</span>
                  </v-btn>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import AppConfig from 'Constants/AppConfig'
import Snackbar from 'Helpers/snackbar/index'

export default {
  data() {
    var t = this
    return {
      loading: false,
      roleId: null,
      new_account: true,
      invitationValid: false,
      fullname_label: '',
      password_label: '',
      confirmpassword_label: '',
      btn_start_label: '',
      invite_user: {},
      invite_user_name: '',
      organization_name: '',
      individual: '',
      business_form: '',
      valid: false,
      full_name: '',
      password: '',
      confirm_password: '',
      name: '',
      activated: false,
      expired: false,
      nameRules: [
        (v) => !!v || '※この項目は必須です',
        (v) => (v && v.replace(/\s/g, '').length >= 1) || '※この項目は必須です',
        (v) => v.length <= 50 || '50' + t.$t('message.character_max_length'),
      ],
      email: '',
      emailRules: [
        (v) => !!v || '※この項目は必須です',
        (v) =>
          /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
            v
          ) || '正しい形式で入力ください',
      ],
      password: '',
      passwordRules: [
        (v) => !!v || '※この項目は必須です',
        (v) =>
          (v &&
            v.length >= 8 &&
            this.isIncludeUpper(v) &&
            this.isIncludeLower(v) &&
            this.isIncludeNumber(v) &&
            this.isIncludeSymbol(v)) ||
          t.$t('message.password_validation'),
        (v) =>
          (v && v.length <= 50) || '50' + t.$t('message.character_max_length'),
      ],
      passwordRules_2: [
        (v) => !!v || '※この項目は必須です',
        (v) =>
          (v &&
            v.length >= 8 &&
            this.isIncludeUpper(v) &&
            this.isIncludeLower(v) &&
            this.isIncludeNumber(v) &&
            this.isIncludeSymbol(v)) ||
          t.$t('message.password_validation'),
        (v) =>
          (v && v.length <= 50) || '50' + t.$t('message.character_max_length'),
        (v) =>
          (v && v == this.password) ||
          t.$t('message.error_password_and_confirm_not_same'),
      ],
      invalid_password_message: '',
      appLogo: AppConfig.appLogo2,
      brand: AppConfig.brand,
      processing: true,
      error: false,
    }
  },
  created() {
    try {
      this.fullname_label = this.$t('message.signup_confirm_full_name')
      this.fullname_hint = this.$t('message.signup_confirm_full_name_hint')
      this.password_label = this.$t('message.signup_confirm_password')
      this.confirmpassword_label = this.$t(
        'message.signup_confirm_confirmpassword'
      )
      this.getInvitationInfo()
    } catch (e) {
      console.log(e)
    }
  },
  methods: {
    isIncludeUpper(text) {
      for (let s of text) {
        if (s.match(/^[A-Z]+$/)) {
          return true
        }
      }
      return false
    },
    isIncludeLower(text) {
      for (let s of text) {
        if (s.match(/^[a-z]+$/)) {
          return true
        }
      }
      return false
    },
    isIncludeNumber(text) {
      for (let s of text) {
        if (!isNaN(s)) {
          return true
        }
      }
      return false
    },
    isIncludeSymbol(text) {
      let reg = new RegExp(/[!"#$%&'()\*\+\-\.,\/:;<=>?@\[\\\]^_`{|}~]/g)
      if (reg.test(text)) {
        return true
      }
      return false
    },
    submit() {
      try {
        if (this.$refs.form.validate()) {
          this.loading = true
          if (!this.new_account) {
            this.confirm_password = this.password
          }
          if (this.password == this.confirm_password) {
            let apiUrl = this.$store.getters.apiUserInviteAccceptFunction
            let data = {
              full_name: this.full_name,
              password: this.password,
              invite_code: this.$route.params.code,
            }
            this.$store
              .dispatch('userAcceptInvite', { data, apiUrl })
              .then((response) => {
                if (response.data.error) {
                  Snackbar.error(response.data.error)
                  this.loading = false
                } else {
                  const message = this.organization_name + 'に参加しました。'

                  const apiUrl = this.$store.getters.apiLoginFunction

                  const user = {
                    email: this.email,
                    password: this.password,
                    envtype: this.$store.getters.getEnvType,
                    organization_name: this.organization_name,
                  }

                  if (this.roleId === 4) {
                    this.invitationValid = true
                    this.activated = true
                    this.loading = false
                  } else {
                    this.$store
                      .dispatch('apiSignInUser', {
                        user,
                        message,
                        apiUrl,
                      })
                      .then((response) => {
                        if (response.data.error) {
                          Snackbar.error(response.data.error)
                        }
                        this.loading = false
                      })
                  }
                }
              })
          } else {
            this.invalid_password_message = this.$t(
              'message.signup_confrim_password_match_error'
            )
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    getInvitationInfo() {
      let apiUrl = this.$store.getters.apiCheckUserInviteUrl
      let data = {
        invite_code: this.$route.params.code,
      }
      this.$store
        .dispatch('checkUserInvite', {
          data,
          apiUrl,
        })
        .then((response) => {
          if ('error' in response.data) {
            this.error = true
          } else {
            const userInvited = response.data.data
            const userSendInvitation = userInvited.user_send_invitation
            this.new_account = userInvited.new_account
            this.roleId = userInvited.user_role.id
            this.email = userInvited.email
            this.invite_user_name = userSendInvitation.name
            this.organization_name = userSendInvitation.organization_name

            if (!this.new_account) {
              this.passwordRules.splice(1, 1)
            }
            if (response.data.data.activated == true) {
              this.activated = true
            } else if (new Date(response.data.data.valid_until) < Date.now()) {
              this.expired = true
            }
          }
          this.processing = false
        })
    },
  },
}
</script>
